export default function useCampsiteAdsAs({ campsite: cp = null }: { campsite?: MaybeRefOrGetter<any | null> }) {
  const campsite = toRef(cp);

  /********************
   * COMPOSITIONS      *
   ********************/
  const appStore = useAppStore();
  const route = useRoute();
  const searchService = useSearchService();
  const { locale } = useI18n();

  const preEnterData = shallowRef(null);
  const { hasProduct } = useCampsiteDetail({
    campsite: () => campsite.value,
    preEnterData: () => preEnterData.value,
  });

  /********************
   * REFS & VARS       *
   ********************/
  const hasAds = computed(() => {
    const canHaveAds = !isPartOfAvailabilityService.value && !hasProduct(8) && !hasProduct(16);
    appStore.floorAdActive = canHaveAds;
    return canHaveAds;
  });

  const isPartOfAvailabilityService = computed(() => !!isIntegrationProvider.value);

  const bookingProvider = computed(() => {
    return preEnterData.value?.booking_provider || campsite.value?.booking_provider;
  });

  const isIntegrationProvider = computed(() => {
    const integrationProvider = [
      'ctoutvert',
      'compusoft',
      'camping.info-bvcd',
      'camping.info-booking',
      'camping.care',
      'phobs',
    ];
    return integrationProvider.includes(bookingProvider.value);
  });

  /********************
   * FUNCTIONS         *
   ********************/
  async function getPreEnterData() {
    const response = await searchService.getCampsitePreEnter(route.params?.identifier, locale.value);
    preEnterData.value = response;
    return response;
  }

  return {
    hasAds,
    isIntegrationProvider,
    isPartOfAvailabilityService,
    preEnterData,
    getPreEnterData,
  };
}
